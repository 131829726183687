// internal/public
export const HOME_URL = '/';
export const BLOG_URL = '/blog';
export const PROJECTS_URL = '/projects';
export const RESUME_URL = '/resume';
export const SIGNIN_URL = '/signin';
export const AUTH_ERROR_URL = '/auth/error';
export const MAKE_MEET_LINK = (duration?: '15min' | '30min' | '60min') =>
  `/meet${duration !== '30min' ? `/${duration || ''}` : ''}`;

// internal/private
export const ADMIN_URL = '/admin';
export const SIGNOUT_URL = '/logout';
export const ADMIN_CONTACTS_URL = '/admin/contacts';
export const ADMIN_FLIGHTS_URL = '/admin/flights';
export const GRAPHQL_API_URL = '/api/graphql';

// external
export const LINKEDIN_PROFILE_URL = 'https://www.linkedin.com/in/mihaianton98/';
export const GITHUB_PROFILE_URL = 'https://github.com/MihaiAnton';
export const FACEBOOK_PROFILE_URL = 'https://www.facebook.com/mihai.anton4';
export const INSTAGRAM_PROFILE_URL = 'https://www.instagram.com/mihaianton98/';
export const AIFLOW_URL = 'https://www.aiflow.ltd';
export const GOOGLE_DRIVE_RESUME_URL =
  'https://drive.google.com/file/d/122PN9DUDtDHUHxkQinuiexHe5BYqRLjo/view?usp=sharing';
export const CALENDLY_15MIN_EMBED_URL = 'https://calendly.com/mihaianton/15min?hide_gdpr_banner=1';
export const CALENDLY_30MIN_EMBED_URL = 'https://calendly.com/mihaianton/30min?hide_gdpr_banner=1';
export const CALENDLY_60MIN_EMBED_URL = 'https://calendly.com/mihaianton/60min?hide_gdpr_banner=1';

// url functions
export const makePostUrl = (id: string, path?: string) => `${path || BLOG_URL}/${id}`;

// public
export const RESUME_PDF = '/resume/MihaiAntonResume.pdf';

// external URL IDs - useful for google analytics tracking
export const GOOGLE_DRIVE_RESUME_LINK_ID = 'GOOGLE_DRIVE_RESUME_LINK';
export const LINKEDIN_LINK_ID = 'LINKEDIN_LINK';
export const INSTAGRAM_LINK_ID = 'INSTAGRAM_LINK';
export const FACEBOOK_LINK_ID = 'FACEBOOK_LINK';
export const AIFLOW_LINK_ID = 'AIFLOW_LINK';
export const GITHUB_LINK_ID = 'GITHUB_LINK';
