import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { InitApolloClient } from 'next-with-apollo';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { APP_URL } from 'constants/env';
import { GRAPHQL_API_URL } from 'constants/urls';

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

export const createApolloClient: InitApolloClient<NormalizedCacheObject> = ({ initialState }) => {
  const uploadLink = link.concat(
    createUploadLink({
      uri: `${APP_URL}${GRAPHQL_API_URL}`,
      credentials: 'same-origin',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any as ApolloLink,
  );

  const links = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    uploadLink,
  ]);

  return new ApolloClient({
    link: links,
    cache: new InMemoryCache().restore(initialState || {}),
  });
};

export { useApolloNetworkStatus };
