import { Analytics } from '@vercel/analytics/react';
import { SessionProvider } from 'next-auth/react';
import App, { AppProps } from 'next/app';
import React from 'react';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';
import { IS_PRODUCTION, NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID } from 'constants/env';
import { GlobalStyles, theme } from 'utils/theme';
import withApollo from 'utils/withApollo';

class MyApp extends App<AppProps> {
  componentDidMount() {
    if (IS_PRODUCTION && NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({
        gtmId: NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      });
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {/* TODO: Add favicon here  */}
        <SessionProvider session={pageProps.session}>
          <Component {...pageProps} />
          <Analytics />
        </SessionProvider>
      </ThemeProvider>
    );
  }
}

export default withApollo(MyApp);
