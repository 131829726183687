export const NODE_ENV = process.env.NODE_ENV || 'unknown';
export const APP_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV || 'local';
export const IS_PRODUCTION = NODE_ENV === 'production';

export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || undefined;

export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || '';

export const NEXT_PUBLIC_APP_URL = process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:3000';

export const APP_URL = process.env.NEXT_PUBLIC_VERCEL_URL
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : NEXT_PUBLIC_APP_URL;

export const ADMIN_EMAIL_ADDRESS =
  process.env.NEXT_PUBLIC_ADMIN_EMAIL_ADDRESS || 'antonmihai58@gmail.com';

// Authentication
export const NEXT_AUTH_SECRET = process.env.NEXTAUTH_SECRET || 'this should be secret';
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || '';
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET || '';

// Notion
export const NOTION_AUTH_TOKEN = process.env.NOTION_AUTH_TOKEN || '';
export const NOTION_USER_ID = process.env.NOTION_USER_ID || '';

// Sendgrid
export const SENDGRID_FROM = process.env.SENDGRID_FROM || '';
export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY || '';

// ZOOM
export const ZOOM_PERSONAL_ROOM_URL =
  process.env.ZOOM_PERSONAL_ROOM_URL ||
  'https://us04web.zoom.us/j/6850516752?pwd=Z1dPMGc1QjkzdUdtais5QjdEMVpZUT09';
